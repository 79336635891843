$( ".regular-button" ).mouseenter(function(e) {
    var parentOffset = $(this).offset(); 
   
    var relX = e.pageX - parentOffset.left;
    var relY = e.pageY - parentOffset.top;
    $(this).prev(".button-circle").css({"left": relX, "top": relY });
    $(this).prev(".button-circle").removeClass("desplode-circle");
    $(this).prev(".button-circle").addClass("explode-circle");
 
 });
 
 $( ".regular-button" ).mouseleave(function(e) {
 
      var parentOffset = $(this).offset(); 
 
      var relX = e.pageX - parentOffset.left;
      var relY = e.pageY - parentOffset.top;
      $(this).prev(".button-circle").css({"left": relX, "top": relY });
      $(this).prev(".button-circle").removeClass("explode-circle");
      $(this).prev(".button-circle").addClass("desplode-circle");
 
 }); 