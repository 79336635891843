$(document).ready(function() { 

    $('.event-date-price-form').hide();

    $('.event-date-price-form').first().addClass('active');

    $('.event-control').click(function() {

        var attribute = $(this).data('event-date');
        $('.event-date-price-form').removeClass('active');

        $('.event-date-price-form[data-event-date="'+attribute+'"]').addClass('active');
    });
});