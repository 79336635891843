/**
 * Script used to fill in the billing address form with the address selected from the saved addresses
 */

$(function () {
    var $page = $('.bill-address-page');
    var $addresses = $page.find('.address-book-select input[name="billing-address-id"]');
    var $form = $page.find('#billing-address');
  
    $addresses.on('change', function () {
      var addressId = $(this)
        .val();

        if (addressId != '-1') {
        // The customer selected an existing address
        var addressData = $(this)
          .parents('.address-content')
          .data();

          Object.entries(addressData)
          .forEach(([property, value]) => {
            if (property === 'id') return;
  
            var fieldName = property.replace(/(_\w)/g, words => words[1].toUpperCase());
            var $field = $form.find('[name*="' + fieldName + '"]');
            console.log($field);
            console.log(value);
            $field.val(value);
            
          });
      } else {
        // The customer wants to create a new address
        $form.find('input:not([name="billing-address-id"])').val('');
      }
    });
  });
  