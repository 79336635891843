$(document).ready(function() {

    if($('body').is('.homepage')) {
        $(document).ready(function() {
            if ($(window).width() > 1024) {
                $('.homepage').css('overflow', 'hidden');
                $('html, body').mousewheel(function(e, delta) {
                    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 || navigator.platform == 'MacIntel') {
                        this.scrollLeft -= (delta * 2);
                    } else {
                        this.scrollLeft -= (delta * 100);
                    }
                });
            } else {
                $('.homepage').css('overflow-y', 'visible');
            }
        });
    };
});
