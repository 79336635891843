$(document).ready(function () {
  $(".popin-close").click(function () {
    $('.popins').addClass('not-visible');
    $('.popin-overlay').addClass('hide-overlay');
  });

  $(".access-site").click(function () {
    $('.popins').addClass('not-visible');
    $('.popin-overlay').addClass('hide-overlay');
  });

  // Hide popin overlay if no popins are defined
  if (!$('.popins').length) {
    $('.popin-overlay').addClass('hide-overlay');
  }
});
