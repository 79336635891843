/**
 * JS for handling ajax event cart add
 *
 * @author Les Vikings <contact@les-vikings.fr>
 * @since 2019-11-07
 */

(function ($, window, undefined) {
  'use strict';

  var eventCartAdd = {
    init: function () {
      this.$body = $('body');
      this.$formPanel = this.$body.find('.event-date-price');
      this.$forms = this.$body.find('.event-date-price-form form');
      this.$loader = this.$body.find('.event-date-price-loader');

      this.bindEvents();
    },

    bindEvents: function () {
      var _this = this;

      // Bind form submission
      this.$forms.on('submit', function (e) {
        e.preventDefault();

        _this.addToCart(this);
      });
    },

    addToCart: function (form) {
      var _this = this;
      var $form = $(form);

      // Show the loader over the forms
      this.$loader.addClass('loading');

      // Hide the form alerts
      this.$formPanel.find('.form-alert').html('').hide();

      // Submit the form
      $.ajax($form.prop('action'), {
        method: 'POST',
        data: $form.serialize()
      })
        .done(function (response) {
          if (response.hasOwnProperty('errors')) {
            _this.$loader.removeClass('loading');
            _this.handleCartAddErrors(response.errors);
          } else {
            // The items have been successfully added to the cart, let's redirect the user
            if (response.hasOwnProperty('redirect')) {
              location.replace(response.redirect);
            } else {
              _this.$loader.removeClass('loading');
              _this.$formPanel.find('.form-alert')
                .removeClass('error success')
                .addClass('success')
                .html('<p>La réservation a bien été ajoutée au panier</p>')
                .show();
            }
          }
        })
        .fail(function (xhr, status, error) {
          // Hide the loader
          _this.$loader.removeClass('loading');

          if (status === 'error' || !xhr.responseJSON) {
            _this.$formPanel.find('.form-alert')
              .removeClass('success error')
              .addClass('error')
              .html('<p>Erreur : '+ error +'</p>')
              .show();
          } else {
            if (xhr.responseJSON.hasOwnProperty('errors')) {
              _this.handleCartAddErrors(xhr.responseJSON.errors);
            }

            // Show an error atop the form panel
            if (xhr.responseJSON.hasOwnProperty('message')) {
              _this.$formPanel.find('.form-alert')
                .removeClass('success error')
                .addClass('error')
                .html(xhr.responseJSON.message)
                .show();
            }
          }
        })
      ;
    },

    handleCartAddErrors: function (errors) {
      var $globalErrors = $('<ul>');
      errors.forEach(function (error) {
        $globalErrors.append('<li>' + error + '</li>');
      });

      this.$formPanel.find('.form-alert').html($globalErrors).addClass('error').show();
    }
  };

  $(document)
    .ready(function () {
      if ($('body').hasClass('event')) {
        eventCartAdd.init();
      }
    });

})(jQuery, window);

