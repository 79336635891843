$(document).ready(function() {
  $(".show-image-hover").click(function(e) { 
    e.preventDefault()   })

      var docWidth = $('.slider-photos').width(),
      $wrap = $('#wrap'),
      $images = $('#wrap .show-image-hover'),
      slidesWidth = $wrap.width();

      $('.slider-photos').on('resize', function(){
        docWidth = $('.slider-photos').width();
        slidesWidth = $wrap.width();
      })

      $wrap.mousemove(function(e) {
        var mouseX = e.pageX,
            offset = mouseX / docWidth * slidesWidth - mouseX / 2;
        
        $images.css({
            'transform': 'translate3d(' + -offset + 'px,0,0)'
        });
      });
});