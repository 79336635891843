$(document).ready(function(){
    $('.description-slick').slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        rows: 0,
        speed: 1500,
        pauseOnHover: true,
        asNavFor: '.pagination-slick'
    });

    $('.description-slick.single-slide').slick('unslick');
    
    $('.pagination-slick').slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        rows: 0,
        speed: 1500,
        vertical: true,
        pauseOnHover: true,
        asNavFor: '.description-slick'
    });
});
