$(document).ready(function () {
  if ($('body').hasClass('event')) {
    if (window.matchMedia("(min-width: 769px)").matches) {
      window.onscroll = function () {
        myFunction()
      };

      var showHead = document.getElementById("show-informations");

      var sticky = showHead.offsetTop - 80;

      function myFunction() {
        if (window.pageYOffset >= sticky) {
          showHead.classList.add("sticky");
          $('.container-sticky-motion').addClass('height');
        } else {
          showHead.classList.remove("sticky");
          $('.container-sticky-motion').removeClass('height');
        }
      }
    }
  }

  if ($('body').hasClass('event')) {
    $(".single-show-button").click(function (e) {
      e.preventDefault()

      function scrollTo(target) {
        if (target.length) {
          $("html, body").stop().animate({scrollTop: (target.offset().top - 200) + 'px'}, 1000);
        }
      }

      scrollTo($(".booking-show"));
    });
  }

  if (!$('body').hasClass('homepage')) {
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      var menu = $('body');

      if (scrollTop > 10) {
        menu.addClass('scrolled');
      } else {
        menu.removeClass('scrolled');
      }
    });
  }
});
