$(document).ready(function(){
    document.addEventListener(
        "scroll",
        function() {
        var scrollLeft =
            document.documentElement["scrollLeft"] || document.body["scrollLeft"];
        var scrollRight =
            (document.documentElement["scrollWidth"] ||
            document.body["scrollWidth"]) - document.documentElement.clientWidth;
        scrollPercent = scrollLeft / scrollRight * 100 + "%";
        document
            .getElementById("_progress")
            .style.setProperty("--scroll", scrollPercent);
        },
        { passive: true }
    );
});