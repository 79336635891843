/**
 * Cart page functions
 */

$(function () {
  if ($('body').hasClass('cart')) {
    $('.sylius-cart-remove-button').on('click', function (e) {
      e.preventDefault();

      var $button = $(this);
      $.post($button.data('url'), {
        '_method': 'DELETE',
        '_csrf_token': $button.data('csrf-token')
      }, function (data) {
        location.replace($button.data('redirect'));
      });
    });
  }
});
