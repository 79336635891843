//open menu

$(document).ready(function(){
  $(".burger").click(function(){
    $('.navigation-bar').toggleClass("is-active");
    $(this).toggleClass("rotate");
  });

  $(".mobile-menu").click(function(){
    $('.sub-nav ul').toggleClass("active");
  });
});
