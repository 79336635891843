var map;

function initMap() {
    var latLng = {
      lat: mapLatitude,
      lng: mapLongitude
    };

    var map = new google.maps.Map(
      document.getElementById('map'),
      {
        zoom: 15,
        center: latLng,
        disableDefaultUI: true,
        zoomControl: true,
        controlSize: 32,
        styles: [
          {
            'featureType': 'administrative',
            'elementType': 'labels.text.fill',
            'stylers': [
              {
                'color': '#444444'
              }
            ]
          },
          {
            'featureType': 'landscape',
            'elementType': 'all',
            'stylers': [
              {
                'color': '#f2f2f2'
              }
            ]
          },
          {
            'featureType': 'poi',
            'elementType': 'all',
            'stylers': [
              {
                'visibility': 'off'
              }
            ]
          },
          {
            'featureType': 'road',
            'elementType': 'all',
            'stylers': [
              {
                'saturation': -100
              },
              {
                'lightness': 45
              }
            ]
          },
          {
            'featureType': 'road.highway',
            'elementType': 'all',
            'stylers': [
              {
                'visibility': 'simplified'
              }
            ]
          },
          {
            'featureType': 'road.arterial',
            'elementType': 'labels.icon',
            'stylers': [
              {
                'visibility': 'off'
              }
            ]
          },
          {
            'featureType': 'transit',
            'elementType': 'all',
            'stylers': [
              {
                'visibility': 'off'
              }
            ]
          },
          {
            'featureType': 'water',
            'elementType': 'all',
            'stylers': [
              {
                'color': '#d3d3d3'
              },
              {
                'visibility': 'on'
              }
            ]
          }
        ]
      }
    );
    var marker = new google.maps.Marker({
        map: map,
        icon: mapMarker,
        position: latLng,
    });
    marker.setMap(map);
  }
