$(document).ready(function(){
    if ($('body').hasClass('event')) {

        $(".show-status").first().addClass("active");

            var ulContainer = document.getElementById("shows-listing-nav");
            var link = ulContainer.getElementsByClassName("show-status");
            for (var i = 0; i < link.length; i++) {
            link[i].addEventListener("click", function(e) {
                e.preventDefault();
                var current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active", "");
                this.className += " active";
            });
        }
    }

  });